@import url('https://fonts.googleapis.com/css2?family=Honk&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');

.App {
  text-align: center;
  height: 100vh;
  width: auto;
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;
}

.title{
  display: inline-block;
  background-color: black;
  border-radius: 50px;
}

.recipe-box{
  display: inline-block;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 2vw;
}

.title > p {
  font-size: xx-large;
  color: white;
  font-family: "Honk", serif;
}

.title2 {
  font-size: x-large;
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.recipe-img{
  width: 50%;
  border-radius: 10px;
}

.ingredient-checkbox {
  margin-right: 20px; /* Adjust the value as needed */
  transform: scale(1.5); /* Adjust the scale value as needed */
  transform-origin: left; /* Ensure the checkbox scales from the left */
}

.ingredient-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard{
  background-color: white;
  padding: 20px;
  margin: 25px;
  border-radius: 10px;
  text-align: center;
}

.text-box {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
  resize: none;
}

.language-line{
  display: inline-flex;
  margin-top: 25px;
}

.every-button {
  transform: scale(1); /* Default scale */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.every-button:hover,
.every-button:focus {
  transform: scale(1.1); /* Enlarge on hover */
}

.betterBt {
  border-radius: 20px;
  background-color: orange;
  font-size: x-large;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease; /* Add a transition for smooth background color change */
  padding: 5px 10px 5px 10px;
  margin: 10px;
}

.betterBt:hover,
.betterBt:focus {
  transform: scale(1.1); /* Enlarge on hover */
  background-color: red; /* Change background color on hover */
  transition: transform 0.1s ease, background-color 0.5s ease; /* Add transition for both transform and background color during click */

}

.betterBt:active {
  transform: scale(0.9); /* Shrink on click */
}

.time-now{
  display: inline-block;
  background-color: black;
  color: white;
  text-align: center;
  font-size: large;
  border-radius: 30px;
  padding: 20px;
  margin-top: 33vh;
}

.card{
  width: 100px;
  padding: 10px;
}

.card-selected{
  width: 100px;
  padding: 10px;
  border: 4px solid red;
}

.card:hover{
  cursor: pointer;
}

.card-table{
  display: inline-flex;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.border-frame{
  border: 4px solid black;
  border-radius: 20px;
  padding: 20px;
}

.input-number{
  margin-left: 10px;
  border-radius: 5px;
  width: 45px;
}

.input-player{
  margin-left: 10px;
  border-radius: 5px;
  width: 55vw;
}

.row-el {
  display: flex;
  align-items: center; /* Vertically center items */
  margin-bottom: 10px; /* Add spacing between rows */
}

.row-el label {
  flex: 1; /* Distribute available space evenly */
  margin-right: 10px; /* Add spacing between label and input */
}

.button-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center buttons horizontally */
  align-items: center; /* Center buttons vertically (optional) */
  /* Add height if you want to see vertical centering effect */
}


.button-grid button {
  flex: 1 1 40%; /* Adjust the percentage to control the button width */
  margin: 5px; /* Optional: for spacing around buttons */
}

/* Optional: Responsive design for smaller screens */
@media (max-width: 600px) {
  .button-grid button {
    flex-basis: 80%; /* Adjust for single column layout */
  }
}

.h1Equivalent {
  font-size: 2em;      /* 2 times the size of the normal text */
  font-weight: bold;  /* Bold font */
  margin-top: 0.67em; /* Default top margin */
  margin-bottom: 0.67em; /* Default bottom margin */
}

.h2Equivalent {
  font-size: 1.5em;     /* Typically 1.5 times the size of normal text */
  font-weight: bold;    /* Bold font */
  margin-top: 0.83em;   /* Default top margin */
  margin-bottom: 0.83em; /* Default bottom margin */
}

.h3Equivalent {
  font-size: 1.17em;    /* Typically 1.17 times the size of normal text */
  font-weight: bold;    /* Bold font */
  margin-top: 1em;      /* Default top margin */
  margin-bottom: 1em;   /* Default bottom margin */
}

.search-bar {
  width: 27vw;
  border: 10px solid #d9a738;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
}

@media (max-width: 600px) {
  .search-bar {
    width: 66vw;
  }
}

.password-field{
  margin: 20px;
  border-radius: 20px;
  font-size: xx-large;
}

@media (max-width: 600px) {
  .password-field{
    font-size: large;
  }
}











